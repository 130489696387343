<template>
  <v-app>
    <core-snackbar />
    <core-menu />
    <v-main>
      <router-view />
    </v-main>
    <core-footer />
  </v-app>
</template>

<script>
export default {
  components: {
    CoreSnackbar: () => import('@/components/general/core/CoreSnackbar'),
    CoreMenu: () => import('@/components/site/core/CoreMenu'),
    CoreFooter: () => import('@/components/site/core/CoreFooter'),
  },

  data: () => ({}),
}
</script>
